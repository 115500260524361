@import './node_modules/ag-grid-community/dist/styles/ag-grid.scss';
@import './node_modules/ag-grid-community/dist/styles/ag-theme-alpine/sass/ag-theme-alpine';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import './theme';
@import './body';
@import './header';
@import './footer';
@import './card';
@import './grid';
@import './form';
@import './modal';
@import './button';
@import './tree';
@import './tabs';

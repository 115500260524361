@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

.ag-theme-alpine {
  .ag-row {
    display: flex;
    align-items: center;
    font-size: 11px;
  }

  .ag-cell,
  .ag-cell-wrapper.ag-row-group {
    display: flex;
    align-items: center;
  }

  .ag-cell {
    background: white;
  }
}

.app-file-upload {
  top: 30%;
  width: 75%;
  height: 100%;
  position: absolute;
  z-index: 400;
}

.disabled-font-color {
  color: #b9b9b9 !important;
}

.copy-icon {
  padding-left: 23.5px;
}

app-tree {
  .card-header {
    padding: 0;
  }

  .card-body {
    padding: .75rem;
    border: none;
  }

  .panel {
    margin-bottom: .5rem;
  }
}

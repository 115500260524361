$th-primary-base-bgcolor: #1565c0;
$th-primary-base-color: #fff;
$th-primary-dark-bgcolor: #003b8e;
$th-primary-dark-color: #fff;
$th-primary-light-bgcolor: #5e92f3;
$th-primary-light-color: #000;

$th-secondary-base-bgcolor: #fbc02d;
$th-secondary-base-color: #000;
$th-secondary-dark-bgcolor: #c49000;
$th-secondary-dark-color: #000;
$th-secondary-light-bgcolor: #fff263;
$th-secondary-light-color: #000;

$th-white: #fff;
$th-grey-light: #f8f8f8;
$th-grey-base: #e0e0e0;
$th-grey-dark: #acacac;

$th-setting-color: #acacac;
$th-core-color: #64b5f6;
$th-routing-color: #9575cd;
$th-map-color: #66bb6a;
$th-fpm-color: #ffb300;
$th-crit-color: #f44336;

.modal-header {
  padding: 0.5rem;
}

.modal-body {
  background-color: $th-grey-light;
}

.modal-lg {
  max-width: 90%;
}

.custom-modal {
  max-width: 55%;
}

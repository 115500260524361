* {
  font-family: Exo, Roboto, "Helvetica Neue", sans-serif;
}

body,
html {
  height: 100%;
  font-size: 90%;
}

body {
  background-color: $th-grey-light;
  display: flex;
  flex-direction: column;
}

.app-container,
.app-content {
  height: 100%;
  padding-bottom: 35px;
}

.jumbotron {
  background-color: $th-primary-base-bgcolor;
  color: $th-primary-base-color;
}

app-root,
app-main,
app-home {
  display: flex;
  flex-direction: column;
  height: 100%;
}

app-loader {
  height: 100%;
  width: 100%;
}

.badge-lg {
  font-size: 0.7rem;
}

.jumbotron {
  padding: 2rem 1rem;
}

.language {
  transition: 250ms all;

  .locale {
    cursor: pointer;

    .flag {
      opacity: 0.7;
      border: solid 1px $th-grey-light;
      height: 20px;

      &:hover,
      &.active {
        opacity: 1;
      }
    }

    &:hover,
    &.active {
      font-weight: bold;
      background-color: $th-grey-light;
    }
  }
}

.ngx-select__item {
  font-size: 0.9rem;
}

.map-header-fontsize {
  font-size: 1rem;
}

.col-0 {
  flex: 0 0 0;
  max-width: 0;
  padding: 0;
  margin: 0;
}

// Locations related stuff
#map {
  height: 100%;
  width: 100%;
}

.leafletMarker {
  &.asset {
    border: solid 2px red;
    background: red;
  }

  &.selected {
    opacity: 1 !important;
  }

  &.not-selected {
    background: transparent !important;
  }
  &.hidden {
    display: none !important;
  }

  &.asset-order-clicked {
    border: solid 2px green;
    background: green;
  }

  &.tag {
    border: solid 2px blueviolet;
    background: blueviolet;
  }

  opacity: 0.5;
  border-radius: 50%;
  height: 12px !important;
  width: 12px !important;
}

li.disabled,
li a.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.modal-dialog.msg-1 h3 {
    font-size: 1.125rem;
}

.multi-modal-backdrop-bg {
    background-color: #00000070;
}

/* ng-select styles */

.ng-select.custom .ng-select-container {
    min-height: auto;
}

.ng-select.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-select.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    display: flex;
}

.three-col {
   height: calc(100% - 40px);
}

.mt-15px {
    margin-top: 15px;
}

// clear filter icon

.clear-filter:after {
    content: "\f715";
    position: absolute;
    font-family: 'Line Awesome Free';
    font-weight: 900;
    top: 0px;
    left: 7px;
    font-size: 14px;
    transform: rotate(90deg);
}

.account-color-span {
    height: 10px;
    width: 50px;
    display: block;
 }
.header {
  background-color: $th-primary-base-bgcolor;
  color: $th-primary-base-color;
  height: 50px;
  box-shadow: 0 0 10px 1px $th-primary-dark-bgcolor;
  font-size: .9rem;

  .brand {
    font-size: 1.2rem;
    margin-top: -.25rem;
  }

  .section {
    border-bottom: solid 2px $th-primary-base-color;
    a:not(.dropdown-item) {
      font-weight: bold;
      text-decoration: none;
      color: $th-primary-base-color;
    }
  }

  .link {
    cursor: pointer;
    transition: all 250ms;
    border-bottom: solid 2px $th-primary-base-bgcolor;
    flex-wrap: nowrap;

    &.selection {
      border-bottom: dashed 2px $th-primary-base-color;
    }

    a:not(.dropdown-item) {
      text-decoration: none;
      color: $th-primary-base-color;
    }

    &:hover {
      border-bottom: solid 2px $th-primary-base-color;
    }
  }

  .dropdown {
    cursor: pointer;
  }

  .user {
    height: 30px;
    border-radius: 50%;
    background-color: $th-primary-base-color;

    .name {
      font-size: .8rem;
      margin-top: -.25rem;
    }

    .i, a {
      color: $th-primary-base-bgcolor !important;
    }

    &:hover {
      border-bottom: solid 2px $th-primary-base-bgcolor;
    }

  }
}

.footer {
  font-size: .8rem;
  background-color: $th-primary-base-bgcolor;
  color: $th-primary-base-color;
  width: 100%;

  a {
    color: $th-primary-base-color;
  }
}

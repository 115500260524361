.card.module {
  min-height: 150px;
  transition: 250ms all;
  cursor: pointer;

  &.setting {
    background: linear-gradient(
          to top right,
          transparent 50%,
          $th-setting-color 0
        )
        top right/30px 30px no-repeat,
      white;
  }

  &.core {
    background: linear-gradient(to top right, transparent 50%, $th-core-color 0)
        top right/30px 30px no-repeat,
      white;
  }

  &.routing {
    background: linear-gradient(
          to top right,
          transparent 50%,
          $th-routing-color 0
        )
        top right/30px 30px no-repeat,
      white;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.fpm {
    background: linear-gradient(to top right, transparent 50%, $th-fpm-color 0)
        top right/30px 30px no-repeat,
      white;
  }

  &.flc {
    background: linear-gradient(to top right, transparent 50%, $th-fpm-color 0)
        top right/30px 30px no-repeat,
      white;
  }

  &.crit {
    background: linear-gradient(to top right, transparent 50%, $th-crit-color 0)
        top right/30px 30px no-repeat,
      white;
  }

  &.map {
    background: linear-gradient(to top right, transparent 50%, $th-map-color 0)
        top right/30px 30px no-repeat,
      white;
  }

  &:hover {
    box-shadow: 0 0 10px 1px $th-grey-base;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.card.main {
  position: relative;
  top: -55px;
}

.list {
  .card-body {
    overflow: auto;
    max-height: 300px;

    a.item {
      transition: 250ms all;
      text-decoration: none;
      cursor: pointer;
      background-color: $th-grey-light;

      &:hover {
        background-color: $th-grey-base;
      }

      .name {
        font-size: 1.1rem;
      }

      .subinfo {
        font-size: 0.8rem;
      }
    }
  }
}

.card.form {
  margin-bottom: 0.5rem;

  .form {
    padding: 0.5em;
  }
}

.height-auto {
  height: auto;
}

.font-size-smaller {
  font-size: 0.9rem;
}

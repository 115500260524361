.btn-xs,
.btn-group-xs > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.5rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}
.rotate {
  transform: rotate(180deg);
}
